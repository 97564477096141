import { Injectable } from "@angular/core";
import { forkJoin, from, Observable, of, Subscription } from "rxjs";
import { Logger } from "../../core/logger/logger";
import { AccountIdentity, AccountPlayerSettings, IdentityService } from "../../core/identity.service";
import { GlobalSettingService } from "../../core/global-setting.service";
import { FeatureService } from "../../core/feature.service";
import { Emitter } from "../../core/emitters/emitter";
import { map as rxJsMap, mergeMap, tap } from "rxjs/operators";
import { AffiliationModelService } from "../../model/identity/affiliation-model.service";
import { Activity } from "../../model/types/content/activity";
import { AccountModelService } from "../../model/identity/account-model.service";
import { first, get, includes, isEmpty, isNumber, isUndefined, map, reduce, some, split, trim } from "lodash-es";
import { StorageCache } from "../../core/storage-cache";
import { Dictionary } from "../../model/types/dictionary";
import {
    ActivitySetting,
    LOCAL_USER_SETTINGS_COMPREHENSIONQUIZENABLED,
    LocalUserSettings,
    SPEAKMODE_READ_MISSING_WORD,
    SPEAKMODE_REPEAT_MISSING_WORD,
    SPEAKMODE_REPEAT_NO_TRANSCRIPTION,
    SPEAKMODE_REPEAT_TRANSCRIPTION
} from "./activity-setting";
import { AssetType } from "../../model/types/content/asset";
import { AccountProperties } from "../../model/types/account-properties";
import { Difficulty } from "../../model/types/difficulty";
import { VOICE_GALLERY, VOICE_PERSONA_DEFAULT, VoicePersona } from "../../talking-mimi-app/voice-persona";
import { AccountAffiliation } from "../../model/types/account-affiliation";
import { Identity } from "../../model/types/identity";

const SPEAKMODE_TARGET_WORD_HIDDEN = [SPEAKMODE_REPEAT_MISSING_WORD, SPEAKMODE_READ_MISSING_WORD];
const SPEAKMODE_TRANSCRIPT_HIDDEN = [SPEAKMODE_REPEAT_NO_TRANSCRIPTION];
const BITWISE_SETTINGS = [
    "autoStart",
    "helpActivated",
    "hiddenChallenge",
    "speakModeAutoAdvance",
    "speakLite",
    "autoPauseWatchMode"
];
const ACCOUNT_PROPERTY_FIELDS = {
    playerTranscript: "playerTranscriptEnabled",
    playerTranslation: "playerTranscriptTranslationEnabled"
};

export class UserSettings {
    isChina: boolean = false;
    cmsMode: boolean = false;
    dynamicMode: boolean = true;
    watchMode: boolean = true;
    learnMode: boolean = true;
    speakMode: boolean = true;
    quizMode: boolean = true;
    chatMode: boolean = true;
    canFavorite: boolean = false;
    activityListEnabled: boolean = false;
    activityDescriptionEnabled: boolean = false;
    activityTabsEnabled: boolean = true;
    //These will always be overridden by the data from AccountSettingService
    lang: string = "";
    autoStart: boolean = false;
    helpActivated: boolean = true;
    hiddenChallenge: boolean = false;
    speakModeAutoAdvance: boolean = false;
    speakLite: boolean = true;
    autoPauseWatchMode: boolean = false;
    isEmbed: boolean = false;
    isSocialMediaOn: boolean = true;
    hideRegwallByConsumerKey: boolean = false;
    showLessonPlan: boolean = true;
    bypassPaywall: boolean = false;
    bypassRegwall: boolean = false;
    isExperiencePointsVisible: boolean = true;
    isComprehensionQuizEnabled: boolean = false;
    recognizerType: number = 0;
    isDialogLineSpeechFeedbackEnabled: boolean = false;
    speakAllLinesToComplete: boolean = false;
    slowSpeakIconVisible: boolean = true;
    showPlayerEndScreenIfModeComplete: boolean = false;
    favoriteEnabled: boolean = true;
    wordDefinitionVisible: boolean = true;
    hideDialogLineTranslation: boolean = false;
    speakInterstitialNextButtonVisible: boolean = true;
    isWatchDialogLineTranslationEnabled: boolean = true;
    isLearnDialogLineTranslationEnabled: boolean = false;
    isSpeakDialogLineTranslationEnabled: boolean = false;
    isMobileScrollIntoViewEnabled: boolean = true;
    isLanguageDefinitionsUsable: boolean = false;
    isPlayerDifficultyHidden: boolean = false;
    isActivityAppHeaderVisible: boolean = true;
    loadingRingThemeClass: string = "primary";
    skipWatchCompletionEventSending: boolean = false;
    isSpeakScoreBucketClickable: boolean = false;
    openResponseChatMode: boolean = false;
    playerTranslation?: boolean;
    playerTranscript?: boolean;
    interstitialsEnabled: boolean = true;
    showSettings: boolean = true;
    showSubtitles: boolean = true;
    showKeyboardShortcuts: boolean = true;
    isPlayerAutoPlayEnabled: boolean = true;
}

export class TranscriptSettings {
    transcriptHidden: boolean = false;
    targetWordHidden: boolean = false;
}

@Injectable({ providedIn: "root" })
export class ActivitySettingService {
    static readonly EVENT_SETTING_UPDATE = "onSettingUpdate";
    static readonly EVENT_LOCAL_SETTING_UPDATE = "onLocalSettingUpdate";
    static readonly EVENT_OPEN_SUBTITLES_PANEL = "onOpenSubtitlesPanel";
    static readonly EVENT_OPEN_SETTINGS_PANEL = "onOpenSettingsPanel";
    static readonly EVENT_CLOSE_PANEL = "onClosePanel";

    private logger = new Logger();
    private emitter = new Emitter();
    private initialized: boolean = false;
    private settings: UserSettings = new UserSettings();
    private localUserSettingsStorage = new StorageCache<Dictionary<any>>("localActivitySettings");
    private comprehensionQuizToggleVisible: boolean = true;
    private appSettings; //The settings passed to the initializer, modified by any calls to set that aren't handled by AccountSettingService
    private currentPromise?: Promise<UserSettings>;

    constructor(private globalSettingService: GlobalSettingService,
                private identityService: IdentityService,
                private featureService: FeatureService,
                private affiliationModelService: AffiliationModelService,
                private accountModelService: AccountModelService) {
    }

    initialize(settings: any): Promise<UserSettings> {
        if (this.isInitialized() && isEmpty(settings)) {
            return Promise.resolve(this.getSettings());
        }

        if (this.currentPromise && isEmpty(settings)) {
            return this.currentPromise;
        }

        let parsedSettings = this.globalSettingService.parseSettings(settings);
        this.appSettings = this.sanitizeSettings(parsedSettings);

        this.currentPromise = this.assembleSettings().toPromise();

        return this.currentPromise;
    }

    isInitialized(): boolean {
        return this.initialized;
    }

    private getAccountProperties(): Observable<AccountProperties[]> {
        if (!this.getAccountId()) {
            return of([] as AccountProperties[]);
        }
        return this.accountModelService.getAccountProperties({ accountIDs: this.getAccountId() });
    }

    private assembleSettings(): Observable<UserSettings> {
        return forkJoin([
            from(this.identityService.initialize()),
            this.getFeatureKnobSettings(),
            this.getAccountProperties()
        ]).pipe(
            tap(() => {
                this.generateLocalUserSettings();
            }),
            rxJsMap(([accountIdentity, featureKnobSettings, [accountProperties]]) => {
                const accountSettings = this.getAccountIdentityPlayerSettings(accountIdentity);

                this.logger.log("%cFK settings", "color: #648c11", featureKnobSettings);
                this.logger.log("%caccount settings", "color: #648c11", accountSettings);
                this.logger.log("%caccount properties", "color: #648c11", accountProperties);
                this.logger.log("%capp override", "color: #648c11", this.appSettings);

                return {
                    ...new UserSettings(),
                    ...featureKnobSettings,
                    lang: accountIdentity.siteLanguage,
                    ...accountSettings,
                    isComprehensionQuizEnabled: accountProperties?.comprehensionQuizEnabled,
                    playerTranscript: accountProperties?.playerTranscriptEnabled,
                    playerTranslation: accountProperties?.playerTranscriptTranslationEnabled,
                    ...this.appSettings
                };
            }),
            mergeMap((settings: UserSettings) => {
                if (!this.getAccountId()) {
                    return forkJoin([of(settings), of({} as AccountAffiliation)]);
                }

                return forkJoin([
                    of(settings),
                    this.affiliationModelService.getAccountAffiliation(this.identityService.getAccountId())
                ]);
            }),
            rxJsMap(([settings, accountAffiliation]) => {
                this.settings = settings;
                this.initialized = true;
                this.setComprehensionQuizToggleValue(accountAffiliation);
                this.setClassSettings(accountAffiliation);
                this.logger.log("%cActivity settings:", "color: #648c11", this.settings);
                this.publish(ActivitySettingService.EVENT_SETTING_UPDATE, this.settings);
                return this.settings;
            })
        );
    }

    private getClassSettingBooleanValue(accountAffiliation: AccountAffiliation, field: string, defaultValue: boolean = false): boolean {
        const classes = get(accountAffiliation, "classes", []);
        return some(classes, affiliationClass => get(affiliationClass, field, defaultValue));
    }

    private setClassSettings(accountAffiliation: AccountAffiliation): void {
        if (isEmpty(accountAffiliation?.classes)) {
            this.logger.log("skip setClassSettings; no classes");
            return;
        }

        if (!this.featureService.getFeature("isChatModeEnabled")) {
            this.logger.log("Feature isChatModeEnabled=FALSE; Disabling both modes");
            this.set("chatMode", false);
            this.set("openResponseChatMode", false);
            return;
        }

        const latestClass = first(accountAffiliation?.classes);
        this.set("chatTranslations", latestClass?.chatTranslations);
        this.set("chatMode", latestClass?.dialogChatEnabled);
        this.set("openResponseChatMode", latestClass?.openResponseChat);
    }

    private setComprehensionQuizToggleValue(accountAffiliation: AccountAffiliation): void {
        if (!this.getAccountId()) {
            return;
        }

        if (this.getClassSettingBooleanValue(accountAffiliation, "comprehensionQuizEnabled")) {
            this.setLocalUserSettings("isComprehensionQuizEnabled", true);
            this.comprehensionQuizToggleVisible = false;
            return;
        }

        const comprehensionQuizEnabledAccountLevel = this.settings.isComprehensionQuizEnabled ?? false;
        const comprehensionQuizEnabledFeatureKnob = this.featureService.getFeature("isAccountComprehensionQuizEnabled") ?? false;
        const comprehensionQuizEnabled = comprehensionQuizEnabledFeatureKnob && comprehensionQuizEnabledAccountLevel;

        this.setLocalUserSettings("isComprehensionQuizEnabled", comprehensionQuizEnabled);
        this.comprehensionQuizToggleVisible = !this.featureService.getFeature("isPlayerComprehensionQuizSettingHidden");
    }

    isComprehensionQuizEnabled(): boolean {
        return this.getLocalUserSetting("isComprehensionQuizEnabled");
    }

    getPlayerResolution(): number {
        return this.getLocalUserSetting<number>("playerVideoResolution")
            || parseInt(this.featureService.getFeature("PlayerVideoResolution"))
            || AssetType.DIALOG_THUMB_SIZE_360P;
    }

    private getSpeakLiteSetting(speakLiteSetting: boolean): boolean {
        let isSpeakLiteEnabled = this.featureService.getFeature("isSpeakLiteEnabled");
        if (isSpeakLiteEnabled == FeatureService.SPEAKLITE_CONFIG.FALSE) {
            return false;
        }

        if (isSpeakLiteEnabled == FeatureService.SPEAKLITE_CONFIG.TRUE) {
            return true;
        }

        return speakLiteSetting;
    }

    private getFeatureKnobSettings(): Observable<UserSettings> {
        return this.featureService
            .generateFeatures()
            .pipe(
                rxJsMap(() => {
                    const FEATURE_MAPPING = {
                        watchMode: "isWatchModeEnabled",
                        learnMode: "isLearnModeEnabled",
                        speakMode: "isSpeakModeEnabled",
                        chatMode: "isChatModeEnabled",
                        speakLite: "isSpeakLiteEnabled",
                        hiddenChallenge: "isHiddenChallengeEnabled",
                        isSocialMediaOn: "isSocialMediaOn",
                        dynamicActivityScreen: "dynamicContentStartScreenBehavior",
                        dynamicMode: "dynamicMode",
                        interstitialsEnabled: "isInterstitialsEnabled",
                        activityTabsEnabled: "isActivityTabsEnabled",
                        activityDescriptionEnabled: "isActivityDescriptionEnabled",
                        activityListEnabled: "isActivityListEnabled",
                        isExperiencePointsVisible: "isExperiencePointsVisible",
                        recognizerType: "recognizerType",
                        isDialogLineSpeechFeedbackEnabled: "isDialogLineSpeechFeedbackEnabled",
                        bypassPaywall: "bypassPaywall",
                        speakAllLinesToComplete: "speakAllLinesToComplete",
                        showPlayerEndScreenIfModeComplete: "showPlayerEndScreenIfModeComplete",
                        hideDialogLineTranslation: "hideDialogLineTranslation",
                        speakInterstitialNextButtonVisible: "speakInterstitialNextButtonVisible",
                        isWatchDialogLineTranslationEnabled: "isWatchDialogLineTranslationEnabled",
                        isLearnDialogLineTranslationEnabled: "isLearnDialogLineTranslationEnabled",
                        isSpeakDialogLineTranslationEnabled: "isSpeakDialogLineTranslationEnabled",
                        isLanguageDefinitionsUsable: "isLanguageDefinitionsUsable",
                        isPlayerDifficultyHidden: "isPlayerDifficultyHidden",
                        showKeyboardShortcuts: "isPlayerKeyboardShortcutsEnabled",
                        isSpeakScoreBucketClickable: "isSpeakScoreBucketClickable",
                        talkingHeadEnabled: "isMimiTalkingHeadEnabled",
                        mimiTtsEnabled: "isMimiTtsEnabled",
                        isPlayerAutoPlayEnabled: "isPlayerAutoPlayEnabled"
                    };

                    return reduce(FEATURE_MAPPING, (acc, featureKey, settingKey) => {
                        // @ts-ignore
                        let featureVariant = this.featureService.getFeature(featureKey);
                        if (!isUndefined(featureVariant)) {
                            acc[settingKey] = featureVariant;
                        }
                        return acc;
                    }, new UserSettings());
                })
            );
    }

    //@TODO do another round of cleanup and should no longer mutate this value
    private sanitizeSettings(settings: any) {
        if (isNumber(settings.speakLite)) {
            settings.speakLite = settings?.speakLite !== FeatureService.SPEAKLITE_CONFIG.FALSE;
        }

        return settings;
    }

    private generateNameSpace(): object {
        return { accountId: this.identityService.getAccountId() };
    }

    private async generateLocalUserSettings(): Promise<void> {
        const LOCAL_USER_SETTINGS_FEATURE_MAPPING = {
            speakExerciseMode: "speakExerciseMode",
            learnDynamicMode: "dynamicMode",
            speakDynamicMode: "dynamicMode",
            playerVideoResolution: "PlayerVideoResolution",
            talkingHeadEnabled: "isMimiTalkingHeadEnabled",
            mimiTtsEnabled: "isMimiTtsEnabled"
        };

        let localStorageSettings = await this.localUserSettingsStorage.getCachePromise(this.generateNameSpace()) ?? {};
        const localUserSettings = reduce(LOCAL_USER_SETTINGS_FEATURE_MAPPING, (acc, featureKey, settingKey) => {
            let localStorageSetting = localStorageSettings ? localStorageSettings[settingKey] : undefined;
            if (!isUndefined(localStorageSetting)) {
                acc[settingKey] = localStorageSetting;
                return acc;
            }
            // @ts-ignore
            let featureVariant = this.featureService.getFeature(featureKey);
            if (!isUndefined(featureVariant)) {
                acc[settingKey] = featureVariant;
            }
            return acc;
        }, localStorageSettings);

        ActivitySetting.setLocalUserSettings(this.identityService.getAccountId(), localUserSettings);
    }

    getSettings(): UserSettings {
        return this.settings;
    }

    getAccountId(): number | undefined {
        return this.identityService.getAccountId();
    }

    getNativeLanguage(): string | undefined {
        return this.identityService.getNativeLanguage();
    }

    get<T>(settingsKey: string): T | undefined {
        return this.settings ? this.settings[settingsKey] : undefined;
    }

    set(key: string, value): void {
        this.logger.log(`Changing activity setting ${key} to ${value}`);
        this.settings[key] = value;
        if (BITWISE_SETTINGS.includes(key)) {
            this.identityService.setPlayerSetting(key, value);
        }
        if (ACCOUNT_PROPERTY_FIELDS[key]) {
            this.updateAccountSettings(ACCOUNT_PROPERTY_FIELDS[key], value);
        }
        this.publish(ActivitySettingService.EVENT_SETTING_UPDATE, this.settings);
    }

    getLocalUserSetting<T>(settingsKey: keyof LocalUserSettings): T | undefined {
        return ActivitySetting.getLocalUserSetting<T>(this.identityService.getAccountId(), settingsKey);
    }

    private updateAccountSettings(key: keyof Identity, value: any): void {
        if (!this.identityService.hasIdentity()) {
            return;
        }
        this.accountModelService.updateAccountProperties({ [key]: value })
            .subscribe();
    }

    setLocalUserSettings(key: keyof LocalUserSettings, value: any): void {
        this.logger.log(`Changing activity setting ${key} to ${value}`);
        const localUserSetting = ActivitySetting.setLocalUserSetting(this.identityService.getAccountId(), key, value);
        this.localUserSettingsStorage.setValue(this.generateNameSpace(), localUserSetting);

        if (key === LOCAL_USER_SETTINGS_COMPREHENSIONQUIZENABLED && this.getAccountId()) {
            this.updateAccountSettings("comprehensionQuizEnabled", value);
        }

        this.publish(ActivitySettingService.EVENT_LOCAL_SETTING_UPDATE, localUserSetting);
    }

    speakLite(): boolean {
        return this.get<boolean>("speakLite");
    }

    isInterstitialsEnabled(): boolean {
        return this.get<boolean>("interstitialsEnabled");
    }

    isDynamicModeEnabled(activityTypeId?: number): boolean {
        if (activityTypeId) {
            if (Activity.isLearnActivity(activityTypeId)) {
                return this.getLocalUserSetting("learnDynamicMode");
            }
            if (Activity.isSpeakActivity(activityTypeId)) {
                return this.getLocalUserSetting("speakDynamicMode");
            }
        }
        return this.get<boolean>("dynamicMode");
    }

    isLearnModeMultipleChoiceEnabled(): boolean {
        return this.getLocalUserSetting("multipleChoiceEnabled") ?? this.featureService.getFeature("isLearnModeMultipleChoiceEnabled") ?? false;
    }

    isAutoPause(): boolean {
        if (!isUndefined(this.getLocalUserSetting("autoPauseWatchMode"))) {
            return this.get("autoPauseWatchMode");
        }

        if (Difficulty.isBeginner(this.identityService.getDifficultyLevel())
            && this.featureService.getFeature("isBeginnerPauseAfterEachLineEnabled")) {
            return true;
        }

        return this.get("autoPauseWatchMode");
    }

    subscribe(eventName: string, successFn: (data?) => void, errorFn?: (e?) => void): Subscription {
        return this.emitter.subscribe(eventName, successFn, errorFn);
    }

    publish(eventName: string, data?: any): void {
        this.emitter.publish(eventName, data);
    }

    getObservable(eventName: string): Observable<any> {
        return this.emitter.getObservable(eventName);
    }

    destroy(): void {
        this.emitter.destroy();
    }

    getTranscriptSettings(activityTypeId: number): TranscriptSettings {
        if (Activity.isSpeakActivity(activityTypeId)) {
            let speakExerciseMode = this.getSpeakExerciseMode();
            return {
                targetWordHidden: includes(SPEAKMODE_TARGET_WORD_HIDDEN, speakExerciseMode),
                transcriptHidden: includes(SPEAKMODE_TRANSCRIPT_HIDDEN, speakExerciseMode)
            };
        }

        if (Activity.isLearnActivity(activityTypeId)) {
            return {
                targetWordHidden: false,
                transcriptHidden: false
            };
        }

        return {
            targetWordHidden: false,
            transcriptHidden: !this.get<boolean>("playerTranscript")
        };
    }

    isSpeakExerciseModeAvailable(speakExerciseMode: number): boolean {
        let availableSpeakExerciseModes = map(split(this.featureService.getFeature("availableSpeakExerciseModes"), ","), (numberString) => {
            return parseInt(trim(numberString));
        });

        return includes(availableSpeakExerciseModes, speakExerciseMode);
    }

    isComprehensionQuizToggleVisible(): boolean {
        return this.comprehensionQuizToggleVisible;
    }

    getSpeakExerciseMode(): number {
        let speakExerciseMode = this.getLocalUserSetting<number>("speakExerciseMode");
        if (!this.isSpeakExerciseModeAvailable(speakExerciseMode)) {
            speakExerciseMode = SPEAKMODE_REPEAT_TRANSCRIPTION;
        }

        return speakExerciseMode;
    }

    private getAccountIdentityPlayerSettings(accountIdentity: AccountIdentity): AccountPlayerSettings {
        let isHiddenChallengeAB = this.featureService.getFeature("isHiddenChallengeAB");
        return {
            ...accountIdentity.playerSettings,
            hiddenChallenge: !this.identityService.isPlayerSettingsModified() && !this.featureService.getFeature("isHiddenChallengeEnabled") ? isHiddenChallengeAB : accountIdentity.playerSettings.hiddenChallenge,
            // Check based on the speakLite integer setting; this is not modifying feature since feature is isSpeakLiteEnabled
            speakLite: this.getSpeakLiteSetting(accountIdentity.playerSettings.speakLite)
        };
    }

    getTalkingHeadVolume(): number {
        const DEFAULT_VOLUME = 100;
        return this.getLocalUserSetting("talkingHeadVolume") ?? DEFAULT_VOLUME;
    }

    isTalkingHeadEnabled(): boolean {
        return this.getLocalUserSetting("talkingHeadEnabled") ?? false;
    }

    isMimiTtsEnabled(): boolean {
        return this.getLocalUserSetting("mimiTtsEnabled") ?? false;
    }

    getVoicePersonaSetting(): VoicePersona {
        return VOICE_GALLERY.find((persona) => persona.name == this.getLocalUserSetting<VoicePersona>("voicePersona")?.name) ?? VOICE_PERSONA_DEFAULT;
    }

    getApplyCostumeSetting(): boolean {
        return this.getLocalUserSetting("applyCostume") ?? false;
    }
}
