import { Injectable } from "@angular/core";
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationStart,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router,
    RouterEvent,
    UrlSegment
} from "@angular/router";
import { ROUTE_COURSES, ROUTE_MY_VIDEOS, ROUTE_VIDEOS } from "../views/browse-view/browse-view.routes";
import { Logger } from "../../core/logger/logger";
import { AuthenticationData } from "../../common-app/authentication-adapters/authentication-data";
import {
    getPartnerIdFromRootRoute,
    isFooterRestrictedForPartner,
    shouldPartnerGetRedirectedToMyRoutes,
    shouldPreventPartnerToReachMyClass
} from "../modules/partners/partners-config/partners.config";
import { MyClassState } from "../modules/my-class-app/my-class-app-state.service";
import { Partner } from "../../model/types/partner";
import {
    ROUTE_AUTHENTICATE,
    ROUTE_PARTNER_BERLITZ,
    ROUTE_PARTNER_DESCOMPLICA,
    ROUTE_PARTNER_KSU,
    ROUTE_PARTNER_ROCKFELLER,
    ROUTE_PARTNER_ROOT,
    ROUTE_PARTNER_SHADOW,
    ROUTE_PARTNER_TELELANGUE,
    ROUTE_PARTNER_UNICESUMAR,
    ROUTE_PARTNER_UNIVALI,
    ROUTE_PARTNER_UPF,
    ROUTE_PARTNER_VIDEOENGLISH,
    ROUTE_PARTNER_VNPT,
    ROUTE_PARTNER_VNPT_LOGIN
} from "./routes.partners";
import { Observable, Subject } from "rxjs";
import { FeatureService } from "../../core/feature.service";
import { AccountIdentity, IdentityService } from "../../core/identity.service";
import { Location } from "@angular/common";
import { Instrumentation } from "../../core/instrumentation/instrumentation";
import { AnalyticsService } from "../../core/analytics";
import { ROUTE_VOCAB_QUIZ } from "../views/vocabulary-view/vocabulary-view.routes";
import { InstrumentationService } from "../../core/instrumentation/instrumentation.service";
import { TwaEventHandler } from "../../activity-app/event-handler/twa-event-handler";
import { get, includes, isEmpty, reduce, some, toString } from "lodash-es";
import { isActiveRoute, slideToElement } from "../../core/utility-functions";
import { Browser } from "../../core/browser";
import {
    ROUTE_ACADEMIC,
    ROUTE_AGORA_MEETING,
    ROUTE_AUTHENTICATION,
    ROUTE_BROWSE,
    ROUTE_CHAT_WIZARD,
    ROUTE_COURSE,
    ROUTE_INDIVIDUAL_SUPPORT,
    ROUTE_INVITE,
    ROUTE_JAL_LANDING,
    ROUTE_KIRIHARA_TEACHER_TOOLS,
    ROUTE_LANDING_AI_SPEAKING_JAPAN,
    ROUTE_LANDING_GAMIFICATION_JAPAN,
    ROUTE_LESSON_PLAN,
    ROUTE_LESSON_REPORT,
    ROUTE_MEETING_ROOM,
    ROUTE_MOBILE_LANDING_JAPAN,
    ROUTE_MOBILE_LANDING_KOREA,
    ROUTE_MOBILE_LANDING_KOREA_CHRISTMAS,
    ROUTE_MY_CLASS,
    ROUTE_MY_ENGLISH,
    ROUTE_NOT_FOUND,
    ROUTE_PAYMENT,
    ROUTE_PAYMENT_CHECKOUT,
    ROUTE_RTC_CLASSROOM,
    ROUTE_SPEAKING,
    ROUTE_STUDENT,
    ROUTE_STUDENTS,
    ROUTE_TEACHER_TOOLS,
    ROUTE_VIDEO,
    ROUTE_VOCABULARY,
    ROUTE_CONVERSATIONS
} from "./routes";
import { filter as rxJsFilter } from "rxjs/operators";

// @FIXME god class, invert the dependency instead
const ROUTES_FOOTER_RESTRICTED = [
    ROUTE_RTC_CLASSROOM,
    ROUTE_AGORA_MEETING,
    ROUTE_MEETING_ROOM,
    ROUTE_STUDENTS,
    ROUTE_STUDENT,
    ROUTE_VOCAB_QUIZ,
    ROUTE_LESSON_PLAN,
    ROUTE_LESSON_REPORT,
    ROUTE_PAYMENT,
    ROUTE_PAYMENT_CHECKOUT,
    ROUTE_SPEAKING,
    ROUTE_CHAT_WIZARD,
    `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_TELELANGUE}`,
    `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_BERLITZ}`,
    `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_DESCOMPLICA}`,
    ROUTE_MOBILE_LANDING_KOREA,
    ROUTE_MOBILE_LANDING_KOREA_CHRISTMAS,
    ROUTE_MOBILE_LANDING_JAPAN,
    ROUTE_JAL_LANDING,
    ROUTE_LANDING_AI_SPEAKING_JAPAN,
    ROUTE_LANDING_GAMIFICATION_JAPAN,
    `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VNPT_LOGIN}`,
    ROUTE_TEACHER_TOOLS
];
const ROUTES_HEADER_RESTRICTED = [ROUTE_RTC_CLASSROOM, ROUTE_AGORA_MEETING, ROUTE_MEETING_ROOM, ROUTE_KIRIHARA_TEACHER_TOOLS, ROUTE_LESSON_PLAN, ROUTE_LESSON_REPORT, ROUTE_PAYMENT, ROUTE_PAYMENT_CHECKOUT, ROUTE_CHAT_WIZARD, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_TELELANGUE}/${ROUTE_AUTHENTICATE}`, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_TELELANGUE}/offers`, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_BERLITZ}/${ROUTE_AUTHENTICATE}`, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_DESCOMPLICA}`, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_SHADOW}`, ROUTE_MOBILE_LANDING_KOREA, ROUTE_MOBILE_LANDING_KOREA_CHRISTMAS, ROUTE_MOBILE_LANDING_JAPAN, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_KSU}`, ROUTE_JAL_LANDING, ROUTE_LANDING_AI_SPEAKING_JAPAN, ROUTE_LANDING_GAMIFICATION_JAPAN, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VNPT_LOGIN}`];

const ROUTES_AUTHENTICATION_REDIRECTION_RESTRICTED = [ROUTE_MY_CLASS, ROUTE_STUDENTS, ROUTE_STUDENT, ROUTE_PARTNER_ROOT, ROUTE_PARTNER_VNPT, ROUTE_PAYMENT, ROUTE_INVITE, ROUTE_MEETING_ROOM, ROUTE_ACADEMIC];
const ROUTES_INITIAL_STEPS_RESTRICTED = [ROUTE_INVITE, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VIDEOENGLISH}`, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VNPT}`, ROUTE_STUDENTS, ROUTE_ACADEMIC];
export const ROUTES_CHAT_RESTRICTED = [ROUTE_RTC_CLASSROOM, ROUTE_AGORA_MEETING, ROUTE_MEETING_ROOM, ROUTE_CHAT_WIZARD, ROUTE_AUTHENTICATION, ROUTE_NOT_FOUND, `${ROUTE_COURSE}/`, `${ROUTE_VIDEO}/`, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VNPT_LOGIN}`, `${ROUTE_CONVERSATIONS}/`];
export const ROUTES_CHAT_MOBILE_RESTRICTED = [ROUTE_RTC_CLASSROOM, ROUTE_AGORA_MEETING, ROUTE_SPEAKING, ROUTE_VOCABULARY, `${ROUTE_COURSE}/`, ROUTE_INDIVIDUAL_SUPPORT, `${ROUTE_VIDEO}/`, `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VNPT_LOGIN}`, `${ROUTE_CONVERSATIONS}/`];

export interface UrlSegmentsChange {
    rootRoute: string;
    prevRoute: string;
    currentRoute: string;
}

@Injectable({ providedIn: "root" })
export class AppRoutingStateService {
    private initialized: boolean = false;
    private lazyModuleNavigating: boolean = false;
    private urlSegments: UrlSegment[];
    private urlSegmentsMergedPaths: string;

    private rootUrlSegmentsMergedPaths: string;
    private prevUrlSegmentsMergedPaths: string;

    // Restrictions
    private routeRestrictionApplicableToFooter: boolean = false;
    private routeRestrictionApplicableToHeader: boolean = false;

    private logger = new Logger();
    private urlSegmentsChange$ = new Subject<UrlSegmentsChange>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private featureService: FeatureService,
        private analyticsService: AnalyticsService,
        private instrumentationService: InstrumentationService,
        private identityService: IdentityService) {
        this.router.events
            .pipe(
                rxJsFilter((event) => event instanceof RouterEvent)
            )
            .subscribe((event): void => {
                this.setLazyModuleNavigationStatus(event as RouterEvent);
                this.setWindowPositionToTop(event as RouterEvent);
                this.setUrlSegments(event as RouterEvent);
                this.trackRouterEvents(event as RouterEvent);
                this.setRestrictions();
            });
    }

    getRootUrlSegmentsMergedPaths(): string {
        return this.rootUrlSegmentsMergedPaths || "";
    }

    getCurrentUrlSegmentsMergedPaths(): string | undefined {
        return this.urlSegmentsMergedPaths;
    }

    getPrevUrlSegmentsMergedPaths(): string | undefined {
        return this.prevUrlSegmentsMergedPaths;
    }

    getUrlSegmentsChange(): Observable<UrlSegmentsChange> {
        return this.urlSegmentsChange$.asObservable();
    }

    private setLazyModuleNavigationStatus(event: RouterEvent): void {
        if (event instanceof RouteConfigLoadStart) {
            this.lazyModuleNavigating = true;
        } else if (event instanceof RouteConfigLoadEnd) {
            this.lazyModuleNavigating = false;
        }
    }

    private setWindowPositionToTop(event: RouterEvent): void {
        if (event instanceof RouteConfigLoadEnd) {
            setTimeout(() => slideToElement(), 0);
        }
    }

    private trackRouterEvents(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            if (TwaEventHandler.isTwaEnabled()) {
                if (event.url.indexOf(ROUTE_PAYMENT) !== -1) {
                    return TwaEventHandler.deeplink("/upgrade");
                }
            }
            this.instrumentationService.setRouteStartTime();
        }
        if (event instanceof NavigationEnd) {
            Instrumentation.setPageName(this.urlSegmentsMergedPaths);
            this.instrumentationService.sendRouteLoadingTime(this.urlSegmentsMergedPaths);
            this.analyticsService.trackPageView(this.urlSegmentsMergedPaths);
        }
    }

    private setUrlSegments(event: RouterEvent): void {
        if ((event instanceof NavigationEnd)) {

            this.prevUrlSegmentsMergedPaths = this.urlSegmentsMergedPaths || "";

            const urlTree = this.router.parseUrl(event.urlAfterRedirects);
            this.urlSegments = get(urlTree, "root.children.primary.segments");
            this.urlSegmentsMergedPaths = reduce(this.urlSegments, (acc, segment) => acc.concat([segment.path]), []).join("/");

            if (!this.initialized) {
                this.rootUrlSegmentsMergedPaths = this.urlSegmentsMergedPaths;
                this.initialized = true;
                this.logger.log(`Root Route: ${this.rootUrlSegmentsMergedPaths}`);
            }

            this.logger.log(`Prev Route: ${this.prevUrlSegmentsMergedPaths}`);
            this.logger.log(`Current Route: ${this.urlSegmentsMergedPaths}`);
            this.urlSegmentsChange$.next({
                prevRoute: this.prevUrlSegmentsMergedPaths,
                currentRoute: this.urlSegmentsMergedPaths,
                rootRoute: this.rootUrlSegmentsMergedPaths
            });
        }
    }

    private setRestrictions(): void {
        this.routeRestrictionApplicableToFooter = some(
            ROUTES_FOOTER_RESTRICTED,
            (routeName) => {
                return this.isActiveRoute(`/${routeName}`);
            }
        ) || this.isCourseRouteActive();
        this.routeRestrictionApplicableToHeader = some(ROUTES_HEADER_RESTRICTED, (routeName) => this.isActiveRoute(`/${routeName}`));
    }

    isActiveRoute(routeUrlSegment: string | RegExp, urlSegmentsMergedPaths?: string): boolean {
        const segmentPaths = urlSegmentsMergedPaths || this.urlSegmentsMergedPaths;
        return isActiveRoute(routeUrlSegment, `/${segmentPaths}`);
    }

    isPrevActiveRoute(routeUrlSegment: string | RegExp): boolean {
        return this.isActiveRoute(routeUrlSegment, this.prevUrlSegmentsMergedPaths);
    }

    isRootRoute(routeUrlSegment: string | RegExp): boolean {
        return this.isActiveRoute(routeUrlSegment, this.rootUrlSegmentsMergedPaths);
    }

    isVideosRouteActive(): boolean {
        return this.isActiveRoute(`${ROUTE_BROWSE}/${ROUTE_VIDEOS}`);
    }

    isCoursesRouteActive(): boolean {
        return this.isActiveRoute(`${ROUTE_BROWSE}/${ROUTE_COURSES}`);
    }

    private isUpfCoursesRouteActive(): boolean {
        return this.isActiveRoute(`${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_UPF}/${ROUTE_COURSES}`);
    }

    isPartnerCoursesRouteActive(): boolean {
        const regex = /^partner\/(.+?)\/courses$/;
        return this.isActiveRoute(regex) && !this.isUpfCoursesRouteActive();
    }

    isCourseRouteActive(): boolean {
        const regex = /^((\/?)(.+\/)?course)\/(\d+)$/;
        return this.isActiveRoute(regex);
    }

    isVideoRouteActive(): boolean {
        const regex = /^((\/?)(.+\/)?video)\/(\d+)$/;
        return this.isActiveRoute(regex);
    }

    isMyClassEnrollPage(): boolean {
        const regex = new RegExp(`^((\/?)${ROUTE_MY_CLASS})\/.+\/enroll$`);
        return this.isActiveRoute(regex);
    }

    isLazyModuleNavigating(): boolean {
        return this.lazyModuleNavigating;
    }

    isEntryRoute(): boolean {
        return includes(this.location.path(), ROUTE_BROWSE) || isEmpty(this.location.path());
    }

    isRouteRestrictionApplicableToFooter(): boolean {
        const partnerId = getPartnerIdFromRootRoute(this.getRootUrlSegmentsMergedPaths(), this.route.snapshot.queryParamMap.get("partnerId")) || this.identityService.getPartnerId();
        return this.routeRestrictionApplicableToFooter || isFooterRestrictedForPartner(partnerId);
    }

    isRouteRestrictionApplicableToHeader(): boolean {
        return this.routeRestrictionApplicableToHeader;
    }

    isPostAuthenticationRedirectionRestricted(): boolean {
        return some(
            ROUTES_AUTHENTICATION_REDIRECTION_RESTRICTED,
            (redirectionRestrictedRoute) => this.isActiveRoute(redirectionRestrictedRoute)
        );
    }

    isInitialStepsRestrictedAfterAuthentication(): boolean {
        return some(
            ROUTES_INITIAL_STEPS_RESTRICTED,
            (redirectionRestrictedRoute) => this.isActiveRoute(redirectionRestrictedRoute)
        );
    }

    isChatRestricted(): boolean {
        if (Browser.isMobile() || Browser.isMobileWebView()) {
            return this.isGeneralRouteChatRestricted() || this.isMobileRouteChatRestricted();
        }
        return this.isGeneralRouteChatRestricted();
    }

    isGeneralRouteChatRestricted(): boolean {
        return some(
            ROUTES_CHAT_RESTRICTED,
            (chatRestrictedRoute) => this.isActiveRoute(chatRestrictedRoute)
        );
    }

    isMobileRouteChatRestricted(): boolean {
        return some(
            ROUTES_CHAT_MOBILE_RESTRICTED,
            (chatRestrictedRoute) => this.isActiveRoute(chatRestrictedRoute)
        );
    }

    hasStudentOrigin(): boolean {
        return [ROUTE_STUDENTS, ROUTE_MY_CLASS, ROUTE_STUDENT]
            .some((route) =>
                this.isRootRoute(route)
                || this.isPrevActiveRoute(route)
                || this.isActiveRoute(route)
            );
    }

    hasPartnerOrigin(): boolean {
        return this.isRootRoute(ROUTE_PARTNER_ROOT);
    }

    redirectAfterAuthentication(
        authenticationData: AuthenticationData | AccountIdentity,
        lastAccessedClassState?: Observable<MyClassState>,
        redirectUrl?: string
    ): Promise<any> {
        // 0. Redirect url (If redirect url exists, redirect to that url)
        if (!isEmpty(redirectUrl)) {
            this.logger.log("Navigating to redirect url...");
            window.location.href = decodeURI(redirectUrl);
            return Promise.resolve();
        }

        // 1. Teacher redirection
        if (get(authenticationData, "isTeacher")) {
            this.logger.log("Navigating to teach page...");
            window.location.href = "/" + (this.featureService.getFeatures().forceTeacherToolsInEnglish ? `${ROUTE_TEACHER_TOOLS}?setLanguage=en` : ROUTE_TEACHER_TOOLS);
            return Promise.resolve();
        }

        // 2. Partner redirections (Partners who have a home page gets redirected here)
        const partnerId = get(authenticationData, "primaryPartnerId") || get(authenticationData, "primaryPartnerID");
        const isPartnerRedirectedToPartnerHome = this.redirectPartner(partnerId);
        if (isPartnerRedirectedToPartnerHome) {
            this.logger.log("Navigating to partner course page...");
            return Promise.resolve();
        }

        // 3. Student redirections
        if (get(authenticationData, "isStudent") && !shouldPreventPartnerToReachMyClass(partnerId)) {
            this.logger.log("Navigating to MyClass...");
            if (!lastAccessedClassState) {
                return this.router.navigate([ROUTE_MY_CLASS], { queryParamsHandling: "preserve" });
            }
            return lastAccessedClassState
                .toPromise()
                .then((myClassState: MyClassState) => {
                    const classId = myClassState?.classId;
                    const groupId = myClassState?.groupId;
                    const navigationPath = [ROUTE_MY_CLASS];
                    if (classId) {
                        navigationPath.push(toString(classId));
                        navigationPath.push(toString(groupId));
                    }
                    return this.router.navigate(navigationPath, { queryParamsHandling: "preserve" });
                });
        }

        // 4. Partners who need to get redirected to MyVideos
        if (shouldPartnerGetRedirectedToMyRoutes(partnerId)) {
            this.logger.log("Navigating to MyVideos for this partner...");
            return this.router.navigate([ROUTE_BROWSE, ROUTE_VIDEOS, ROUTE_MY_VIDEOS], { queryParamsHandling: "preserve" });
        }

        if (this.featureService.isMyEnglishEnabled()) {
            this.logger.log("Navigating to MyEnglish...");
            return this.router.navigate([ROUTE_MY_ENGLISH], { queryParamsHandling: "preserve" });
        }

        // 5. Default navigation
        let isBrowseVideoSelectionEnabled = this.featureService.getFeature("isBrowseVideoSelectionEnabled") ?? true;
        if (!isBrowseVideoSelectionEnabled) {
            this.logger.log("Navigating to MyVideos...");
            return this.router.navigate([ROUTE_BROWSE, ROUTE_VIDEOS, ROUTE_MY_VIDEOS], { queryParamsHandling: "preserve" });
        }
        this.logger.log("Navigating to Videos...");
        return this.router.navigate([ROUTE_BROWSE, ROUTE_VIDEOS], { queryParamsHandling: "preserve" });
    }

    redirectPartner(partnerId: number): boolean {
        if (partnerId == Partner.PARTNER_ID_ROCKFELLER) {
            window.location.href = [ROUTE_PARTNER_ROOT, ROUTE_PARTNER_ROCKFELLER, ROUTE_COURSES].join("/");
            return true;
        }
        if (partnerId == Partner.PARTNER_ID_UNIVALI) {
            window.location.href = [ROUTE_PARTNER_ROOT, ROUTE_PARTNER_UNIVALI, ROUTE_COURSES].join("/");
            return true;
        }
        if (partnerId == Partner.PARTNER_ID_UNICESUMAR) {
            window.location.href = [ROUTE_PARTNER_ROOT, ROUTE_PARTNER_UNICESUMAR, ROUTE_COURSES].join("/");
            return true;
        }
        // Other partners should be written here
        return false;
    }

    destroy(): void {
        this.urlSegmentsChange$.complete();
    }
}
