import { VisemeFrame } from "../model/chat/tts-reponse";

export class VoicePersona {
    name: string;
    voice: {
        azure: string;
        google: string;
    };
    bodyType?: string;
    pitch?: string;
    rate?: string;
    testFrame?: VisemeFrame[];
    testAudioUrl?: {
        azure: string;
        google: string;
    };
}

export const TTS_AZURE = "azure";
export const TTS_GOOGLE = "google";

export const PROVIDER_ID_AZURE = 1;
export const PROVIDER_ID_GOOGLE = 2;

export const US_MALE = "us-m";
export const US_FEMALE = "us-f";
export const BRITISH_MALE = "british-m";
export const BRITISH_FEMALE = "british-f";

export const VOICE_PERSONA_DEFAULT: VoicePersona = {
    name: "Jen",
    voice: {
        azure: "en-US-CoraNeural",
        google: "en-US-Wavenet-H"
    },
    bodyType: US_FEMALE,
    testAudioUrl: {
        azure: require("./test-voice/azure/us-f.mp3"),
        google: require("./test-voice/google/us-f.mp3")
    },
    testFrame: [
        {
            "visemeId": 0,
            "offset": 500000
        },
        {
            "visemeId": 14,
            "offset": 1000000
        },
        {
            "visemeId": 4,
            "offset": 2125000
        },
        {
            "visemeId": 19,
            "offset": 2750000
        },
        {
            "visemeId": 15,
            "offset": 3375000
        },
        {
            "visemeId": 14,
            "offset": 3875000
        },
        {
            "visemeId": 5,
            "offset": 4750000
        },
        {
            "visemeId": 13,
            "offset": 5500000
        },
        {
            "visemeId": 19,
            "offset": 5875000
        },
        {
            "visemeId": 6,
            "offset": 6500000
        },
        {
            "visemeId": 20,
            "offset": 7125000
        },
        {
            "visemeId": 20,
            "offset": 7625000
        },
        {
            "visemeId": 14,
            "offset": 8000000
        },
        {
            "visemeId": 6,
            "offset": 8375000
        },
        {
            "visemeId": 16,
            "offset": 9000000
        },
        {
            "visemeId": 19,
            "offset": 9875000
        },
        {
            "visemeId": 1,
            "offset": 10375000
        },
        {
            "visemeId": 20,
            "offset": 11000000
        },
        {
            "visemeId": 4,
            "offset": 11625000
        },
        {
            "visemeId": 17,
            "offset": 12750000
        },
        {
            "visemeId": 1,
            "offset": 13125000
        },
        {
            "visemeId": 13,
            "offset": 13875000
        },
        {
            "visemeId": 0,
            "offset": 15370000
        }
    ]
};


export const VOICE_GALLERY: VoicePersona[] = [
    {
        name: "Brad",
        voice: {
            azure: "en-US-JasonNeural",
            google: "en-US-Casual-K"
        },
        bodyType: US_MALE,
        testAudioUrl: {
            azure: require("./test-voice/azure/us-m.mp3"),
            google: require("./test-voice/google/us-m.mp3")
        },
        testFrame: [
            {
                "visemeId": 0,
                "offset": 500000
            },
            {
                "visemeId": 14,
                "offset": 1000000
            },
            {
                "visemeId": 4,
                "offset": 2000000
            },
            {
                "visemeId": 19,
                "offset": 2750000
            },
            {
                "visemeId": 15,
                "offset": 3625000
            },
            {
                "visemeId": 14,
                "offset": 4000000
            },
            {
                "visemeId": 5,
                "offset": 4875000
            },
            {
                "visemeId": 13,
                "offset": 5500000
            },
            {
                "visemeId": 19,
                "offset": 6125000
            },
            {
                "visemeId": 6,
                "offset": 6625000
            },
            {
                "visemeId": 20,
                "offset": 7375000
            },
            {
                "visemeId": 20,
                "offset": 7750000
            },
            {
                "visemeId": 14,
                "offset": 8250000
            },
            {
                "visemeId": 6,
                "offset": 8625000
            },
            {
                "visemeId": 16,
                "offset": 9250000
            },
            {
                "visemeId": 19,
                "offset": 9875000
            },
            {
                "visemeId": 1,
                "offset": 10500000
            },
            {
                "visemeId": 20,
                "offset": 11125000
            },
            {
                "visemeId": 4,
                "offset": 11750000
            },
            {
                "visemeId": 17,
                "offset": 12875000
            },
            {
                "visemeId": 1,
                "offset": 13375000
            },
            {
                "visemeId": 13,
                "offset": 14000000
            },
            {
                "visemeId": 0,
                "offset": 15620000
            }
        ]
    },
    VOICE_PERSONA_DEFAULT,
    {
        name: "Arthur",
        voice: {
            azure: "en-GB-RyanNeural",
            google: "en-GB-Neural2-B"
        },
        bodyType: BRITISH_MALE,
        testAudioUrl:{
            azure: require("./test-voice/azure/british-m.mp3"),
            google: require("./test-voice/google/british-m.mp3")
        },
        testFrame: [
            {
                "visemeId": 0,
                "offset": 500000
            },
            {
                "visemeId": 14,
                "offset": 1000000
            },
            {
                "visemeId": 4,
                "offset": 2125000
            },
            {
                "visemeId": 19,
                "offset": 2750000
            },
            {
                "visemeId": 15,
                "offset": 3500000
            },
            {
                "visemeId": 14,
                "offset": 4000000
            },
            {
                "visemeId": 5,
                "offset": 4875000
            },
            {
                "visemeId": 19,
                "offset": 6000000
            },
            {
                "visemeId": 6,
                "offset": 6625000
            },
            {
                "visemeId": 20,
                "offset": 7250000
            },
            {
                "visemeId": 20,
                "offset": 7875000
            },
            {
                "visemeId": 14,
                "offset": 8250000
            },
            {
                "visemeId": 6,
                "offset": 8750000
            },
            {
                "visemeId": 16,
                "offset": 9250000
            },
            {
                "visemeId": 19,
                "offset": 10000000
            },
            {
                "visemeId": 1,
                "offset": 10625000
            },
            {
                "visemeId": 20,
                "offset": 11250000
            },
            {
                "visemeId": 4,
                "offset": 12000000
            },
            {
                "visemeId": 17,
                "offset": 13000000
            },
            {
                "visemeId": 1,
                "offset": 13625000
            },
            {
                "visemeId": 0,
                "offset": 15120000
            }
        ]
    },
    {
        name: "Liz",
        voice: {
            azure: "en-GB-AbbiNeural",
            google: "en-GB-Neural2-A"
        },
        bodyType: BRITISH_FEMALE,
        testAudioUrl: {
            azure: require("./test-voice/azure/british-f.mp3"),
            google: require("./test-voice/google/british-f.mp3")
        },
        testFrame: [
            {
                "visemeId": 0,
                "offset": 500000
            },
            {
                "visemeId": 14,
                "offset": 1000000
            },
            {
                "visemeId": 4,
                "offset": 1875000
            },
            {
                "visemeId": 19,
                "offset": 2750000
            },
            {
                "visemeId": 15,
                "offset": 3375000
            },
            {
                "visemeId": 14,
                "offset": 4125000
            },
            {
                "visemeId": 5,
                "offset": 5000000
            },
            {
                "visemeId": 19,
                "offset": 6375000
            },
            {
                "visemeId": 6,
                "offset": 7125000
            },
            {
                "visemeId": 20,
                "offset": 7875000
            },
            {
                "visemeId": 20,
                "offset": 8500000
            },
            {
                "visemeId": 14,
                "offset": 9000000
            },
            {
                "visemeId": 6,
                "offset": 9500000
            },
            {
                "visemeId": 16,
                "offset": 10125000
            },
            {
                "visemeId": 19,
                "offset": 11000000
            },
            {
                "visemeId": 1,
                "offset": 11625000
            },
            {
                "visemeId": 20,
                "offset": 12250000
            },
            {
                "visemeId": 4,
                "offset": 12750000
            },
            {
                "visemeId": 17,
                "offset": 13750000
            },
            {
                "visemeId": 1,
                "offset": 14250000
            },
            {
                "visemeId": 0,
                "offset": 16250000
            }
        ]
    }
];
